// eslint-disable-next-line no-undef
$( ( $ ) => {
	const vcCommon = require( '../common' );
	const slider = $(
		'.bs-section--slider-counter .slick-slider, .bs-section--slider-counter .bs-slider-content.slick-slider, .bs-section--slider-counter .bs-slider-accordion-slick'
	);

	const loadSliderCounter = () => {
		slider.each( ( i, element ) => {
			const targetNode = element;
			const config = {
				attributes: false,
				childList: true,
				subtree: false,
			};

			$( element ).slick( 'unslick' );
			$( element ).on( 'afterChange', function ( e, slick ) {
				const counter = getSliderCounterNode( slick.$slider, slick );
				counter.text(
					`${ slick.currentSlide + 1 }/${ slick.slideCount }`
				);
				slick.$slider.append( counter );
			} );

			// if the element is slider accordion
			if ( $( element ).hasClass( 'bs-slider-accordion-slick' ) ) {
				const accordionId = $( element ).attr( 'id' );
				$( element ).on(
					'afterChange',
					function ( event, slick, currentSlide ) {
						const target = $( `#${ accordionId } .slick-current` )
							.find( '.accordion__block__btn' )
							.data( 'target' );

						const floatingPanel = $(
							`#${ accordionId } .bs-slider-accordion__floating-panel[data-parent='${ target }']`
						);

						//add active class to current slick active item
						$( `#${ accordionId } .card` ).removeClass( 'active' );
						$( `#${ accordionId } .slick-current .card` ).addClass(
							'active'
						);

						//change aria-expanded attribute in block btn
						$( `#${ accordionId } .accordion__block__btn` ).attr(
							'aria-expanded',
							'false'
						);
						$(
							`#${ accordionId } .slick-current .accordion__block__btn`
						).attr( 'aria-expanded', 'true' );

						//add show class to active block btn
						$( `#${ accordionId } .card div` ).removeClass(
							'show'
						);
						$(
							`#${ accordionId } .slick-current ${ target }`
						).addClass( 'show' );

						//add show class to folting panel
						if ( target && floatingPanel ) {
							floatingPanel.siblings().removeClass( 'show' );
							floatingPanel.addClass( 'show' );
						}
					}
				);
			}

			const observerObj = new MutationObserver(
				( mutationList, observer ) => {
					for ( const mutation of mutationList ) {
						if (
							mutation.type === 'childList' &&
							mutation.addedNodes.length > 0 &&
							mutation.addedNodes[ 0 ].className.includes(
								'slick-next'
							)
						) {
							const slick = $( element ).slick( 'getSlick' );
							const counter = getSliderCounterNode(
								slick.$slider,
								slick
							);
							counter.text(
								`${ slick.currentSlide + 1 }/${
									slick.slideCount
								}`
							);
							$( element ).append( counter );
							observer.disconnect();
						}
					}
				}
			);
			observerObj.observe( targetNode, config );

			$( element ).slick( JSON.parse( element.dataset.slick ) );
		} );
	};

	const getSliderCounterNode = ( sliderElm, sl ) => {
		let sliderCounter = sliderElm.find( '.slick-count' );
		if ( sliderCounter.length === 0 && sl.slideCount > 1 ) {
			sliderCounter = $( '<div class="slick-count"></div>' );
			sliderElm.append( sliderCounter );
			return sliderCounter;
		} else {
			return $( sliderCounter[ 0 ] );
		}
	};

	window.addEventListener(
		'load',
		vcCommon.debounce( () => {
			loadSliderCounter();
			slider.each( ( index, ele ) => {
				if (
					ele.slick.breakpointSettings[
						ele.slick.activeBreakpoint
					] !== 'unslick'
				) {
					ele.slick.refresh();
				}
			} );
		}, 10 )
	);
} );
