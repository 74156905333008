// eslint-disable-next-line no-undef
$( ( $ ) => {
	const addPlayIcon = () => {
		const playIconTypes = [ 'Video', 'Webinars' ];
		const showDateTypes = [ 'Blog' ];
		$( '.bs-post .bs-post__category span' ).each( ( index, element ) => {
			const typeText = $( element ).text();
			// Add play button to video and Webinar on Single post
			if ( playIconTypes.includes( typeText ) ) {
				$( element ).closest( '.bs-post' ).addClass( 'video' );
			}
			// Add show-date class
			if ( ! showDateTypes.includes( typeText ) ) {
				$( element ).closest( '.bs-post' ).addClass( 'hide-date' );
			}
		} );
	};

	// Add play icon when page load
	$( window ).on( 'load', () => {
		addPlayIcon();
	} );

	const post = $( '.bs-post' );
	if ( post.length > 0 ) {
		post.has( '.bs-post__image' ).addClass(
			'bs-post__container--has-image'
		);
	}
} );
