$(function() {
    const audioContainer = $('.sd-audio-container');
    if (!audioContainer.length) return;
    audioContainer.each((index, element) => {
        const container = $(element);
        const audio = container.find('#sd-audio-player');
        const playButton = container.find('#sd-audio-play-button');
        const playText = playButton.data('sd-play-button-text');
        const pauseText = playButton.data('sd-pause-button-text');
        playButton.on('click', () => {
            if (audio[0].paused) {
                audio.trigger('play');
                playButton.text(pauseText);
                container.addClass('playing');
                //pause other playing buttons and reset those button to playing from start 
                const otherButtonContainers = audioContainer.not(container);
                otherButtonContainers.each((otherBtnIndex, otherContainerElement) => {
                    otherContainer = $(otherContainerElement);
                    otherAudio = otherContainer.find('#sd-audio-player');
                    otherPlayButton = otherContainer.find('#sd-audio-play-button');
                    otherAudio.trigger('pause');
                    otherAudio[0].currentTime = 0;
                    otherPlayButton.text(playText);
                    otherContainer.removeClass('playing');
                })
            } else {
                audio.trigger('pause');
                playButton.text(playText);
                container.removeClass('playing');
            }
        });
        audio.on('ended', function () {
            playButton.text(playText)
            container.removeClass('playing');
        });
    });
});