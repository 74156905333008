// eslint-disable-next-line no-undef
$( ( $ ) => {
	$( '.notify-bar .close-icon' ).on( 'click', () => {
		$( '.notify-bar' ).addClass( 'notify-bar-closed' );
		$( 'body' ).addClass( 'notify-bar-removed' );
		$.ajax( {
			type: 'POST',
			url: '/wp-admin/admin-ajax.php',
			data: {
				action: 'mark_notify_bar_as_read',
				link: $( '#notify-bar a' ).attr( 'href' ),
			},
		} );
	} );
} );
