// eslint-disable-next-line no-undef
$( ( $ ) => {
	const getDetailsTopNode = ( post ) => {
		let detailsTop = '';
		const postBlock = $( post );
		detailsTop = postBlock.find( '.bs-post__details-top' );
		const postCategory = postBlock.find(
			'.bs-post__category, .bs-post__resource-type'
		);
		const postDate = postBlock.find( '.bs-post__date' );
		if ( detailsTop.length === 0 ) {
			detailsTop = $( '<div class="bs-post__details-top"></div>' );
			detailsTop.append( postCategory ).append( postDate );
			post.append( detailsTop );
			return detailsTop;
		} else {
			return $( detailsTop[ 0 ] );
		}
	};

	const postRestructure = () => {
		const singlePost = $(
			'.bs-section--resource-banner .bs-post, .bs-section--resources-posts-list .bs-post'
		);
		singlePost.each( ( i, element ) => {
			const postDetailsTop = getDetailsTopNode( $( element ) );
			const postInner = $( element ).find( '.bs-post__details' );
			$( postDetailsTop ).prependTo( $( postInner ) );
		} );
	};

	postRestructure();

	$( document ).on( 'facetwp-loaded', () => {
		postRestructure();
	} );
} );
