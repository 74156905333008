// eslint-disable-next-line no-undef
$( ( $ ) => {
	const partnersPosts = $(
		'.bs-section--partners-post, .bs-section--press-release , .bs-section--leadership-post-list'
	);
	const $actForms = $( '#wrapper-div .bs-div' );
	const $actFormsBtns = $(
		'.bs-section--partners-post #wrapper-div .bs-pro-button .bs-pro-button__container, .bs-section--press-release #wrapper-div .bs-pro-button .bs-pro-button__container, .bs-section--leadership-post-list #wrapper-div .bs-pro-button .bs-pro-button__container'
	);

	if ( $( partnersPosts ).length > 0 ) {
		$actFormsBtns.each( function () {
			$( this ).attr( 'data-target', $( this ).attr( 'href' ) );
		} );
		partnersPosts
			.find( '.bs-pro-button:first-of-type > a , .bs-div:first-of-type' )
			.addClass( 'active' );
		$actFormsBtns.on( 'click', function ( e ) {
			e.preventDefault();
			$actFormsBtns.add( $actForms ).removeClass( 'active' );
			$( this ).add( $( this.dataset.target ) ).addClass( 'active' );
		} );
	}
} );
