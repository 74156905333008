// eslint-disable-next-line no-undef
$( ( $ ) => {
	const $timelineWrapper = $( '.timeline-wrapper' ),
		$scroller = $timelineWrapper.find( '.timeline-inner' );
	const clickUp = () => {
		const scrollValue = $scroller.scrollTop() - 200;
		$scroller.animate(
			{
				scrollTop: scrollValue,
			},
			1000
		);
	};
	const clickDown = () => {
		const scrollValue = $scroller.scrollTop() + 200;
		$scroller.animate(
			{
				scrollTop: scrollValue,
			},
			1000
		);
	};
	$timelineWrapper.find( '.btn-up' ).click( () => {
		clickUp();
	} );
	$timelineWrapper.find( '.btn-down' ).click( () => {
		clickDown();
	} );

	const timeLine = [];
	$timelineWrapper.find( '.timeline__content' ).each( ( index, element ) => {
		$( element ).addClass( index % 2 === 0 ? 'odd' : 'even' );
		timeLine.push( $( element ) );
		return timeLine;
	} );

	$timelineWrapper.find( '.btn-up' ).addClass( 'disabled' );
	$scroller.scroll( ( e ) => {
		const $this = $( e.target );
		if (
			$this.scrollTop() + $this.innerHeight() >=
			$this[ 0 ].scrollHeight
		) {
			$this.parent().find( '.btn-down' ).addClass( 'disabled' );
		} else {
			$this.parent().find( '.btn-down' ).removeClass( 'disabled' );
		}
		if ( $this.scrollTop() === 0 ) {
			$this.parent().find( '.btn-up' ).addClass( 'disabled' );
		} else {
			$this.parent().find( '.btn-up' ).removeClass( 'disabled' );
		}
	} );
	$( '.timeline-wrapper' ).removeClass( 'invisible' );
} );
