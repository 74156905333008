// eslint-disable-next-line no-undef
( function ( $ ) {
	const windowWidth = $( window ).width();
	$.debounce = function ( func, wait, immediate ) {
		let timeout;
		return function () {
			const context = this,
				args = arguments;
			const later = function () {
				timeout = null;
				if ( ! immediate ) func.apply( context, args );
			};
			const callNow = immediate && ! timeout;
			clearTimeout( timeout );
			timeout = setTimeout( later, wait );
			if ( callNow ) func.apply( context, args );
		};
	};

	const footerNavMobile = () => {
		const $footer = $( 'footer' ),
			$footerMenu = $footer.find( '.menu-wrapper' ),
			$dropDowns = $footerMenu.find(
				'.navbar-nav > .menu-item-has-children'
			);

		$footer.addClass( 'accordion-active' );

		$dropDowns.each( ( index, element ) => {
			const menuItem = $( element );

			menuItem.find( '> a' ).on( 'click', ( e ) => {
				e.preventDefault();

				if ( ! menuItem.hasClass( 'opened' ) ) {
					$dropDowns.find( '> .sub-menu' ).slideUp( 300 );
					$dropDowns.removeClass( 'opened' );

					menuItem.find( '.sub-menu' ).slideDown( 300 );
					menuItem.addClass( 'opened' );
				} else {
					menuItem.find( '> .sub-menu' ).slideUp( 300 );
					menuItem.removeClass( 'opened' );
				}
			} );
		} );
	};

	$( window ).on(
		'resize',
		$.debounce( function () {
			if (
				$( window ).width() > 575 &&
				$( '.bs-section--home-companies-trust' ).length
			) {
				footerNavMobile();
			}
		}, 300 )
	);

	$( function () {
		if ( windowWidth < 768 && $( 'footer' ).length ) {
			footerNavMobile();
		}
	} );
	// eslint-disable-next-line no-undef
} )( jQuery );
