// eslint-disable-next-line no-undef
$( ( $ ) => {
	const sectionClass = '.bs-section--ratings-and-reviews';

	const formatDate = ( date ) => {
		const monthNames = [
			'JAN',
			'FEB',
			'MAR',
			'APR',
			'MAY',
			'JUN',
			'JUL',
			'AUG',
			'SEP',
			'OCT',
			'NOV',
			'DEC',
		];
		const dateObj = new Date( date );
		return (
			monthNames[ dateObj.getMonth() ] +
			' ' +
			dateObj.getDate() +
			', ' +
			dateObj.getFullYear()
		);
	};

	$( sectionClass ).each( ( index, element ) => {
		const allUrl = $( element ).data( 'all-url' );
		const allBtn =
			'<a class="view-all-btn" href="' +
			allUrl +
			'" target="_blank">View All</a>';
		$( element ).find( '.section__headline' ).append( allBtn );

		const reviewHeader = $( element ).find( '.we-customer-review__header' );
		reviewHeader.each( ( i, e ) => {
			const dateTime = $( e ).find( 'time' );
			const formattedDate = formatDate( dateTime.attr( 'datetime' ) );
			dateTime.text( formattedDate );
		} );

		const commentDiv = $( element ).find( '.we-customer-review' );
		commentDiv.each( ( i, e ) => {
			$( e ).find( 'blockquote' ).not( ':first' ).remove();
			$( e ).find( 'h3' ).not( ':first' ).remove();
		} );

		const reviewBody = $( element ).find( '.we-customer-review__body' );
		reviewBody.each( ( i, e ) => {
			const pTag = $( e ).find( 'p' );
			const pVal = pTag.text();
			if ( pVal.length > 149 ) {
				pTag.addClass( 'read-more-content' );
				pTag.addClass( 'hide' );
				$( e ).append( '<span class="read-more-btn hide">More</span>' );
			}
		} );
	} );

	$( sectionClass ).on( 'click', '.read-more-btn', ( m ) => {
		const btn = $( m.currentTarget );
		btn.toggleClass( 'hide' );
		btn.toggleClass( 'show' );
		const content = btn.parent().find( 'p' );
		if ( content.hasClass( 'hide' ) ) {
			content.removeClass( 'hide' );
			content.addClass( 'show' );
			btn.text( 'Less' );
		} else {
			content.removeClass( 'show' );
			content.addClass( 'hide' );
			btn.text( 'More' );
		}
	} );
} );
