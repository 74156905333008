// eslint-disable-next-line no-undef
$( ( $ ) => {
	const $menuWrapper = $( '.bs-section--feature-navigation' ),
		headerHeight = parseInt( $( 'header .header__inner' ).height() );
	let heightMenu = 0,
		adminBarHeight = 0;
	if ( $( '#wpadminbar' ).length ) {
		adminBarHeight = parseInt( $( '#wpadminbar' ).outerHeight() );
	}
	const isElementPartiallyInViewport = ( el ) => {
		if ( typeof $ !== 'undefined' && el instanceof $ ) el = el[ 0 ];
		const rect = el.getBoundingClientRect();
		const windowHeight =
			window.innerHeight || document.documentElement.clientHeight;
		const vertInView =
			rect.top <= windowHeight / 2 && rect.top + rect.height >= 0;
		return vertInView;
	};

	const scrollFunc = () => {
		const scrolledHeight = $( window ).scrollTop();
		if ( $menuWrapper.length ) {
			heightMenu = $menuWrapper.offset().top - headerHeight - 50;
		}
		if ( $menuWrapper.length ) {
			if ( $( window ).width() > 767 ) {
				if ( scrolledHeight >= heightMenu ) {
					$menuWrapper.addClass( 'position-set' );
				} else {
					$menuWrapper.removeClass( 'position-set' );
				}
			}
		}

		$( 'main section' ).each( ( index, element ) => {
			const el = $( element ),
				inVpPartial = isElementPartiallyInViewport( el );
			if ( inVpPartial ) {
				el.addClass( 'active viewed' );
			} else {
				el.removeClass( 'active' );
			}
			const activeSection = $( 'section.active' ).last().attr( 'id' );
			$menuWrapper.find( '.menu-item' ).removeClass( 'active' );
			if ( activeSection !== '' && activeSection !== undefined ) {
				$menuWrapper
					.find( `a[href='#${ activeSection }']` )
					.parent( '.menu-item' )
					.addClass( 'active' );
			}
		} );
	};

	const debounce = ( func ) => {
		let timer;
		return ( event ) => {
			if ( timer ) clearTimeout( timer );
			timer = setTimeout( func, 100, event );
		};
	};

	if ( $( '.bs-section--feature-navigation' ).length ) {
		$( window ).on( {
			load: () => {
				scrollFunc();
				if ( $( window.location.hash ).length > 0 ) {
					const offsetPosition =
						$( window.location.hash ).offset().top -
						$( 'header .container' ).height() -
						$( '.bs-section--feature-navigation' ).outerHeight() -
						adminBarHeight;
					$( 'html, body' ).stop().animate(
						{
							scrollTop: offsetPosition,
						},
						1000
					);
				}
			},
			resize: () => {
				debounce( () => {
					scrollFunc();
				} );
			},
			scroll: () => {
				scrollFunc();
			},
		} );
		$menuWrapper
			.find( '.menu-item a[href^="#"]' )
			.each( ( index, element ) => {
				const $this = $( element );
				$this.on( 'click', ( e ) => {
					e.preventDefault();
					if ( $( $this.attr( 'href' ) ).length ) {
						const offsetPosition =
							$( $this.attr( 'href' ) ).offset().top -
							$( 'header .header__inner' ).height() -
							$(
								'.bs-section--feature-navigation'
							).outerHeight() -
							adminBarHeight;
						$( '.bs-section--feature-navigation' )
							.find( 'li' )
							.removeClass( 'active' );
						$this.parent().addClass( 'active' );
						$( 'html, body' ).stop().animate(
							{
								scrollTop: offsetPosition,
							},
							1000
						);
					}
				} );
			} );
	}

	$( '.bs-section--feature-navigation' )
		.find( 'a[href^="#"]' )
		.each( ( index, element ) => {
			const $this = $( element );
			$this.on( 'click', ( e ) => {
				e.preventDefault();
				if ( ! $this.parent().hasClass( 'menu-item' ) ) {
					if (
						$( $this.attr( 'href' ) ).length &&
						$.trim( $this.attr( 'href' ) ).length > 1
					) {
						const offsetPosition =
							$( $this.attr( 'href' ) ).offset().top -
							$( 'header' ).outerHeight();
						$( 'html, body' ).stop().animate(
							{
								scrollTop: offsetPosition,
							},
							1000
						);
					}
				}
			} );
		} );
} );
