// $(() => {
// 	//Scroll to slides
// 	const speed = 1000; // The speed of the scroll in milliseconds
// 	$( '.bs-section--scroll-to-section' )
// 		.find( 'a[href^="#"]' )
// 		.filter(
// 			(i, a) =>
// 				a.getAttribute('href').startsWith('#') ||
// 				a.href.startsWith(`${location.href}#`)
// 		)
// 		.unbind('click.smoothScroll')
// 		.bind('click.smoothScroll', (event) => {
// 			const targetId = event.currentTarget
// 				.getAttribute('href')
// 				.split('#')[1];
// 			console.log("targetId", targetId);
// 			const targetElement = document.getElementById(targetId);
// 			console.log("targetElement", targetElement);
// 			if (targetElement) {
// 				event.preventDefault();
// 				$('html, body').animate(
// 					{
// 						scrollTop:
// 							$(targetElement).offset().top -
// 							$('header').outerHeight(),
// 					},
// 					speed
// 				);
// 			}
// 		});
// });
$(() => {
	if ($(".bs-section--scroll-to-section").length > 0) {
		//Scroll to slides
		const speed = 1000; // The speed of the scroll in milliseconds
		$('a[href*="#"]')
			.filter(
				(i, a) =>
					a.getAttribute('href').startsWith('#') ||
					a.href.startsWith(`${location.href}#`)
			)
			.unbind('click.smoothScroll')
			.bind('click.smoothScroll', (event) => {
				const targetId = event.currentTarget
					.getAttribute('href')
					.split('#')[1];
				const targetElement = document.getElementById(targetId);
				if (targetElement) {
					event.preventDefault();
					$('html, body').animate(
						{scrollTop: $(targetElement).offset().top - $('header').outerHeight()},
						speed
					);
				}
			});
	}
});
