function sameHeight( element ) {
	const ele = $( element );
	heights = [];
	ele.each( ( index, element ) => {
		const $this = $( element ),
			height = $this.css( 'height', 'auto' ).height();
		heights.push( height );
	} );
	const maxHeight = Math.max.apply( null, heights );
	ele.each( () => {
		ele.height( maxHeight );
	} );
}

function debounce( func, wait ) {
	let timeout;
	return function executedFunction( ...args ) {
		const later = () => {
			clearTimeout( timeout );
			func( ...args );
		};
		clearTimeout( timeout );
		timeout = setTimeout( later, wait );
	};
}

module.exports = {
	sameHeight,
	debounce,
};
