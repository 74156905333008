$(() => {
	const windowWidth = $( window ).width();
  let lastId,
    topMenu = $( '.bs-section--scroll-menu ul.inner-menu, .bs-section--scroll-menu-latest ul.inner-menu' ),
    menuItems = topMenu.find( 'a' ),
    expandButton = $( '.bs-section--scroll-menu .bs-column .bs-pro-button, .bs-section--scroll-menu-latest .bs-column .bs-pro-button.bs-pro-button--expand-collapse' ),
    scrollItems = menuItems.map( function(){
      let item = $( $( this ).attr( 'href' ) );
      if ( item.length ) { return item; }
    } );

  function menuScrollHandler() {
    menuItems.click( function( e ){
      let href = $( this ).attr( 'href' ),
        offsetTop = href === '#' ? 0 : $( href ).offset().top;
        let windowWidth = $(window).width();
        if ( windowWidth > 992 ) {
          $( 'html, body' ).stop().animate( {
            scrollTop: offsetTop - 180
          }, 850 );
        } else {
          $( 'html, body' ).stop().animate( {
            scrollTop: offsetTop - 80
          }, 850 );
        }
      e.preventDefault();
    } );
  };

  const collapseContent = () => {
    $( ".bs-section--scroll-menu-latest #drop-btn" ).on( "click", function() {
      $( "#drop-btn + div" ).slideToggle( "fast", function() {
        $('.bs-section--scroll-menu-latest #drop-btn').toggleClass('content-closed');
      });
    });
  }

  collapseContent();

// Bind to scroll
  $( window ).scroll( function(){
    let fromTop = $( this ).scrollTop();
    let cur = scrollItems.map( function(){
      if ( $( this ).offset().top < fromTop+220 )
        return this;
    } );
    cur = cur[cur.length-1];
    let id = cur && cur.length ? cur[0].id : '';

    if ( lastId !== id ) {
      lastId = id;
      menuItems
        .parent().removeClass( 'active' )
        /* eslint-disable-next-line */
        .end().filter("[href='#" + id + "']").parent().addClass( 'active' );
    }
  } );

  if ( windowWidth > 767 ) {
    expandButton.click( function( e ){
      if( $( this ).parent( '.bs-section--scroll-menu, .bs-section--scroll-menu-latest' ).find( '.bs-column' ) ){
        $( '.bs-section--scroll-menu, .bs-section--scroll-menu-latest' ).toggleClass( 'collapsed' );
        $( '.bs-column--scroll-menu, .bs-section--scroll-menu-latest' ).toggleClass( 'left' );
        $( '.bs-column--scroll-content' ).toggleClass( 'right' );
      }
    });
  }

  $(window).on('load resize', function() {
    menuScrollHandler();
  });

} );
