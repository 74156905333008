// eslint-disable-next-line no-undef
$( ( $ ) => {
	const headlinePlacement = ( sectionClass, helperClass ) => {
		$( `${ sectionClass }` ).each( ( index, ele ) => {
			const div = $( ele ).find( `${ helperClass }` );
			const dot = $( ele ).find( `.bs-slider-tabs` );
			const duplicate = $( ele ).find(
				`.bs-slider-tabs ${ helperClass }`
			);
			$( ele ).find( duplicate ).remove();
			$( ele ).find( div ).clone().prependTo( dot );
		} );
	};
	$( window ).on( 'load', () => {
		headlinePlacement( '.bs-section--platform-tab-slider', 'h2' );
	} );

	// resize event fire on drag end
	window.addEventListener(
		'resize',
		debounce( () => {
			// Image accordion function
			headlinePlacement( '.bs-section--platform-tab-slider', 'h2' );
		} )
	);

	// set resize event delay for drag
	function debounce( func ) {
		let timer;
		return ( event ) => {
			if ( timer ) clearTimeout( timer );
			timer = setTimeout( func, 100, event );
		};
	}
} );
