// eslint-disable-next-line no-undef
$( function () {
	const videos = document.querySelectorAll( '.master-slider-parent' ); // Select ALL the Videos
	const observer = new IntersectionObserver( ( entries ) => {
		entries.forEach( ( entry ) => {
			if ( ! entry.isIntersecting ) {
				const iframe = document.querySelector( 'iframe' );
				const player = $f( iframe );
				player.api( 'pause' );
			} else {
			}
		} );
	}, {} );
	for ( const video of videos ) observer.observe( video ); // Observe EACH video

	jQuery('.ms-slide-vpbtn').each((index, element) => {
		const $this = jQuery(element);
		const $iframe = $this.parents('.ms-slide').find('iframe');
		$iframe.attr({ 'allow': 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'});
		const $src = $iframe.attr('src');
		$this.on('click', (e) => {
			e.preventDefault();
			let $iframe = jQuery(e.target).parents('.ms-slide').find('iframe');
			const iframeSrc = $iframe.attr('src');
			if(iframeSrc.indexOf("muted=1") > -1) {
				const newSrc = iframeSrc.replace("muted=1", "muted=0");
				setTimeout(function() { 
					$iframe.attr("src",newSrc);
				}, 50);
		 	} else if (iframeSrc.indexOf("mute=1") > -1) {
				const newSrc = iframeSrc.replace("mute=1", "mute=0");
				setTimeout(function() { 
					$iframe.attr("src",newSrc);
				}, 50);
			}
		});
	});

});
