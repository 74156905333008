// eslint-disable-next-line no-undef
$( ( $ ) => {
	const vcCommon = require( '../common' );
	let $priceChartDiv = '';
	if ( $( '.bs-section--all-for-a-low-price' ) ) {
		$priceChartDiv =
			'.bs-section--all-for-a-low-price .bs-div:nth-of-type(2)';

		const addEqualHeight = vcCommon.debounce( () => {
			if ( $( window ).width() > 991 ) {
				const winWidth = document.documentElement.clientWidth;
				const counterBlock = $( $priceChartDiv );
				if ( winWidth > 767 ) {
					vcCommon.sameHeight( counterBlock );
				}
			} else {
				$( $priceChartDiv ).css( 'height', 'auto' );
			}
		}, 10 );

		addEqualHeight();
		$( window ).on( 'load resize', function () {
			addEqualHeight();
		} );
	}
} );
