// eslint-disable-next-line no-undef
(function ($) {
	$.debounce = function (func, wait, immediate) {
		let timeout;
		return function () {
			const context = this,
				args = arguments;
			const later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			const callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};
	let logoChanged = false;
	const sliderAccordion = (sliderAccordionElm) => {
		const $sliderAccordion = sliderAccordionElm,
			$sliders = $sliderAccordion.find(
				'.bs-slider-accordion__floating-panel'
			),
			$tabs = $sliderAccordion.find('.bs-slider-accordion-slick');

		$sliders.each((id, el) => {
			const $logoImage = $(el).find('.bs-div .bs-div .media-elements'),
				$logoTab = $tabs.find('.card').eq(id),
				$tabInner = $logoTab.find('.card-header'),
				$mobileTabInner = $tabs
					.find('.card')
					.eq(id)
					.find('.card-header span');
			let $title = '';
			$title = $tabInner.html();

			if ($(window).width() > 575 && !logoChanged) {
				if ($sliders.length === id + 1) {
					logoChanged = true;
				}

				$tabInner.empty().append($logoImage.html());
				$logoImage.empty().html($title);
			} else if ($(window).width() < 575 && logoChanged) {
				const logo = $mobileTabInner.html();
				$mobileTabInner.empty().append($logoImage.find('span').html());

				$logoImage.empty().append(logo);
				if ($sliders.length === id + 1) {
					logoChanged = false;
				}
			}
		});
	};

	if ($('.bs-section--home-companies-trust').length) {
		sliderAccordion(
			$('.bs-section--home-companies-trust .bs-slider-accordion')
		);
	}

	$(window).on(
		'resize',
		$.debounce(function () {
			if ($('.bs-section--home-companies-trust').length) {
				sliderAccordion(
					$('.bs-section--home-companies-trust .bs-slider-accordion')
				);
			}
		}, 300)
	);
	// eslint-disable-next-line no-undef
})(jQuery);
