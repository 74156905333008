(function ($) {
	const Cookies = require('js-cookie');
	const params = new URLSearchParams(window.location.search);
	const utm_params = [];
	var existing_cookies = {};

	// retriving old cookies
	if (Cookies.get('utm_variables') != undefined) {
		existing_cookies = JSON.parse(Cookies.get('utm_variables'));
	}

	// applying cookies to var
	params.forEach(function (value, key) {
		if (key.startsWith('utm_') && key != 'utm_pagesource') {
			// update existing cookie var
			existing_cookies[key] = value;
		}
	});

	// update cookies
	Cookies.set('utm_variables', JSON.stringify(existing_cookies));

	if (!$.isEmptyObject(existing_cookies) || Cookies.get('utm_variables') != undefined) {
		Object.keys(existing_cookies).forEach(function(key) {
			utm_params.push(key + '=' + existing_cookies[key])
		})

		var utm_search = utm_params.join('&');

		function applyUTMS() {
			$("a[href^='https://go.soundhound.com/']").each((i) => {
				var current_link = $($("a[href^='https://go.soundhound.com/']")[i]);
				var link_url = current_link.attr('href');
				var link_class = new URL(link_url);
				var localPath = '';
				if (window.location.pathname == '/' ) {
					localPath = 'soundhound';
				} else {
					var local_slugs = window.location.pathname.split("/").reverse();
					for (let i = 0; i < local_slugs.length; i++) {
						const element = local_slugs[i];
						if (element.length > 0) {
							localPath = element;
							break;
						}	
					}
				}

				var new_url = link_class.origin + link_class.pathname + '?utm_pagesource=' + localPath.replace(/^\/|\/$/g, '') + '&' + utm_search;
				current_link.attr('href', new_url);
			});
		}

		if (document.readyState === "complete") {
			applyUTMS();
		} else {
			window.addEventListener("load", function () {
				applyUTMS();
			});
		}

	}
})(jQuery);