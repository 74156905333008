require( 'slick-carousel-latest' );
( function ( $ ) {
	const initSlick = () => {
		if ( $( '[data-slick]' ).length ) {
			$( '[data-slick]' )
				.not( '.bs-slider-accordion-slick' )
				.not( '.slick-initialized' )
				.slick();
		}
	};

	const debounce = ( func, wait ) => {
		let timer;
		return ( event ) => {
			if ( timer ) clearTimeout( timer );
			timer = setTimeout( func, wait, event );
		};
	};

	initSlick();

	// Re-initiating the unslicked sliders when resizing the browser  window
	$( window ).resize( () => {
		debounce( () => {
			initSlick();
			const refresh_Slider = $(
				'.bs-section--slider-refresh .slick-initialized'
			);
			refresh_Slider.each( function ( key ) {
				if ( refresh_Slider[ key ] && refresh_Slider[ key ].slick ) {
					refresh_Slider[ key ].slick.refresh();
				}
			} );
		}, 500 )();
	} );
} )( jQuery );

// Append Btn Div inside of advanced accordion
$( () => {
	if ( $( '.bs-section--wake-word-etablish-your' ).length ) {
		$(
			'.bs-section--wake-word-etablish-your .bs-advance-accordion .bs-advance-accordion__right-container'
		).append( $( '.bs-div--advanced-acc-btn' ) );
	}

	if ( $( '.bs-section--platform-always-on' ).length ) {
		$(
			'.bs-section--platform-always-on .bs-advance-accordion .bs-advance-accordion__right-container'
		).append(
			$( '.bs-section--platform-always-on .bs-pro-button--replacing-btn' )
		);
	}
} );
